import { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { extend, Canvas, useFrame } from '@react-three/fiber';
import { MapControls, Sky, Cloud } from '@react-three/drei';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { SoftShadows } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';

const PLANE_SIZE = 2 ** 8;
const PLANE_TEXTURE_REPEAT = PLANE_SIZE / 2;
const SHADOW_SIZE = 2 ** 11;

// extend THREE to include OrbitControls
extend({ MapControls });

// const svgLoader = new SVGLoader();
// const SCALE = 0.1;
// const OFFSET_X = 20;
// const OFFSET_Y = -40;
// function getSVGData() {
//   return new Promise((resolve, reject) => {
//     svgLoader.load('/temuchina.svg', function (data) {
//       const raw = data.paths;

//       let parsed = raw
//         .filter((e) => e.currentPath)
//         .map((e) =>
//           e.subPaths[0].curves.map((e) => [
//             [SCALE * e.v1.x - OFFSET_X, -1 * SCALE * e.v1.y - OFFSET_Y],
//             [SCALE * e.v2.x - OFFSET_X, -1 * SCALE * e.v2.y - OFFSET_Y],
//           ])
//         );

//       resolve(parsed);
//     });
//   });
// }

// function getSVGData() {
//   return new Promise((resolve, reject) => {
//     svgLoader.load(
//       '/temuchina.svg',
//       function (data) {
//         const paths = data.paths;
//         const group = new THREE.Group();

//         let leShapes = [];

//         for (let i = 0; i < paths.length; i++) {
//           const path = paths[i];

//           const material = new THREE.MeshBasicMaterial({
//             color: path.color,
//             side: THREE.DoubleSide,
//             depthWrite: false,
//           });

//           const shapes = SVGLoader.createShapes(path);

//           for (let j = 0; j < shapes.length; j++) {
//             const shape = shapes[j];
//             const geometry = new THREE.ShapeGeometry(shape);
//             const mesh = new THREE.Mesh(geometry, material);

//             leShapes.push(shape);
//             group.add(mesh);
//           }
//         }
//         resolve(leShapes);
//       },
//       function (xhr) {
//         console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
//       },
//       function (error) {
//         console.log('An error happened');
//       }
//     );
//   });
// }

// import { getData } from './get-data';
// const DATA = getData();

// function Box(props) {
//   const {
//     size = 3,
//     rotation = [0, 0, 0],
//     position = [0, 0, 0],
//     color = '#7f8c8d',
//   } = props;

//   return (
//     <mesh castShadow position={position} rotation={rotation}>
//       <boxBufferGeometry attach="geometry" args={[size, size, size]} />
//       <meshLambertMaterial
//         attach="material"
//         color={color}
//         side={THREE.DoubleSide}
//       />
//     </mesh>
//   );
// }

function polar2cartesian({ distance, angle }) {
  return {
    x: distance * Math.cos(angle),
    y: distance * Math.sin(angle),
  };
}

function cartesian2polar({ x, y }) {
  return {
    distance: Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)),
    angle: Math.atan2(y, x),
  };
}

function lineToPolygon(line, weight = 1) {
  const [p1, p2] = line;
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  const polar = cartesian2polar({ x: x2 - x1, y: y2 - y1 });
  const newCoord = polar2cartesian({
    distance: weight,
    angle: polar.angle - Math.PI / 2,
  });

  return [
    [x1 - newCoord.x, y1 - newCoord.y],
    [x1 + newCoord.x, y1 + newCoord.y],
    [x2 + newCoord.x, y2 + newCoord.y],
    [x2 - newCoord.x, y2 - newCoord.y],
  ];
}

function LineDraw({ line, weight = 0.05, height = 0.2, ...props }) {
  const [x, y] = [0, 0];
  const polygon = lineToPolygon(line, weight);

  const shape = useMemo(() => {
    const [start, ...remaining] = polygon;

    const shape = new THREE.Shape();
    shape.moveTo(...start);

    remaining.forEach((coord) => {
      shape.lineTo(...coord);
    });

    return shape;
  }, [polygon]);

  return (
    <>
      <mesh castShadow receiveShadow position={[x, y, -1.1]}>
        <extrudeGeometry
          attach="geometry"
          args={[
            shape,
            {
              bevelOffset: 0,
              bevelSize: 0,
              bevelThickness: 0,
              depth: height,
              bevelSegments: 1,
              ...props,
            },
          ]}
        />
        <meshPhongMaterial attach="material" color={'#000'} />
      </mesh>
    </>
  );
}

function pathToLines(path) {
  const lines = [];

  for (let x = 0; x < path.length - 1; x++) {
    lines.push([path[x], path[x + 1]]);
  }

  lines.push([path[path.length - 1], path[0]]);

  return lines;
}

// function Delimiter({ color = '#d35400', path, ...props }) {
//   const lines = pathToLines(path);
//   return lines.map((line, lineIdx) => <LineDraw key={lineIdx} line={line} />);
// }

function Plane({
  color = '#368d7e',
  size = [PLANE_SIZE, PLANE_SIZE],
  ...props
}) {
  // const texture = useLoader(THREE.TextureLoader, `/grass.jpg`);
  const texture = useLoader(THREE.TextureLoader, `/ground-compressed.jpg`);

  if (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(PLANE_TEXTURE_REPEAT, PLANE_TEXTURE_REPEAT);
    texture.anisotropy = 16;
  }

  const position = [0, 0, -1];

  //  <meshPhongMaterial attach="material" color={color} />

  return (
    <mesh receiveShadow position={position}>
      {/* <planeGeometry attach="geometry" args={size} receiveShadow />*/}
      <circleGeometry attach="geometry" args={size} receiveShadow />
      {texture && (
        <meshPhysicalMaterial
          attach="material"
          map={texture}
          metalness={0}
          clearcoat={0}
          receiveShadow
        />
      )}
    </mesh>
  );
}

function SkyContainer() {
  const skyRef = useRef();
  const lightRef = useRef();

  const SUN_POSITION = [15, (new Date().getTime() / 1000) % 15, 15];

  useFrame((_, delta) => {
    const SUN_POSITION = [15, (new Date().getTime() / 1000) % 15, 15];
    skyRef.current.material.uniforms.sunPosition.value = SUN_POSITION;
    lightRef.current.position.y = SUN_POSITION[1];
  });

  return (
    <>
      <Sky
        ref={skyRef}
        distance={450000}
        sunPosition={SUN_POSITION}
        inclination={0.5}
        azimuth={0.5}
        rayleigh={0.2}
        turbidity={0.2}
      />
      <pointLight
        ref={lightRef}
        intensity={0.75}
        penumbra={1}
        position={SUN_POSITION}
        castShadow
        shadow-bias={-0.00004}
        shadow-mapSize-width={SHADOW_SIZE}
        shadow-mapSize-height={SHADOW_SIZE}
      />
    </>
  );
}

function App() {
  // const [svgData, svgDataSet] = useState([]);

  const casona = useLoader(GLTFLoader, '/glb/mi-casona.glb');
  const escuela = useLoader(GLTFLoader, '/glb/escuela.glb');

  casona.scene.traverse(function (node) {
    if (node.isMesh) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.geometry.computeVertexNormals();
      // node.shadow = {
      //   mapSize: {
      //     width: 512,
      //     height: 512,
      //   },
      //   radius: 5,
      // };
    }
  });

  escuela.scene.traverse(function (node) {
    if (node.isMesh) {
      node.castShadow = true;
      node.receiveShadow = true;
      node.geometry.computeVertexNormals();
      // node.shadow = {
      //   mapSize: {
      //     width: 512,
      //     height: 512,
      //   },
      //   radius: 5,
      // };
    }
  });

  const Home = () => {
    return <primitive object={casona.scene} rotation={[Math.PI / 2, 0, 0]} />;
  };

  const Escuela = () => {
    return (
      <primitive
        object={escuela.scene}
        rotation={[Math.PI / 2, 0, 0]}
        scale={10}
      />
    );
  };

  return (
    <div id="canvas-container">
      <Canvas
        frameloop="always"
        shadows={true}
        dpr={[1, 2]}
        camera={{
          position: [-10, 5, -10],
          zoom: 1,
        }}
        style={{
          height: window.innerHeight,
          width: window.innerWidth,
        }}
      >
        <SkyContainer />

        <hemisphereLight intensity={0.1} />

        <group rotation={[-Math.PI / 2, 0, 0]}>
          <spotLight
            position={[64, 64, 256]}
            angle={0.5}
            penumbra={1}
            intensity={0.1}
            castShadow
            shadow-bias={-0.00004}
            shadow-mapSize-width={SHADOW_SIZE}
            shadow-mapSize-height={SHADOW_SIZE}
          />
          <spotLight
            position={[-320, -320, 256]}
            angle={0.5}
            penumbra={1}
            intensity={0.25}
            castShadow
            shadow-bias={-0.00004}
            shadow-mapSize-width={SHADOW_SIZE}
            shadow-mapSize-height={SHADOW_SIZE}
          />
          <Plane />
          {/* {svgData.map((house, houseIdx) => {
            return (
              <Delimiter
                key={houseIdx}
                path={house.map((house) => house[0])}
                color={'#d35400'}
              />
            );
          })} */}
        </group>

        <group
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.03}
          position={[1.4, -1, 4]}
        >
          <Home />
        </group>

        <group
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
          scale={0.03}
          position={[30, -1, 0]}
        >
          <Escuela />
        </group>

        <MapControls
          target={[1, 2, 3]}
          enablePan={true}
          enableZoom={true}
          enableRotate={true}
          minDistance={0}
          maxDistance={100}
          minPolarAngle={-Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
        />
      </Canvas>
      {/* 
      <pre
        style={{
          pointerEvents: 'none',
          lineHeight: 1,
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        {JSON.stringify(svgData, 0, 2)}
      </pre> */}
    </div>
  );
}

export default App;
